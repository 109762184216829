import { SubscriptionPlans, SubscriptionStatus } from "@prisma/client";

import Button from "@musicfy/components/Button";
import Divider from "@musicfy/components/Divider";
import CancelSubscriptionButton from "@musicfy/components/PaymentButtons/CancelSubscriptionButton";
import StartCheckoutFlowButton from "@musicfy/components/PaymentButtons/StartCheckoutFlowButton";
import { usePaddleUpdatePayment } from "@musicfy/libs/PaddleProvider/hooks";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";
import { formatPrice, getLocale } from "@musicfy/utils";

import { SettingsPanel, type TSettingsPanel } from "../../types";

interface SubscriptionDetailsProps {
  setActivePanel: (panel: TSettingsPanel) => void;
}

const SubscriptionDetails = ({
  setActivePanel,
}: SubscriptionDetailsProps): JSX.Element => {
  const { subscription, createStripePortalSession, paddleSubscription } =
    useSubscriptionContext();
  const { startUpdatePayment } = usePaddleUpdatePayment();

  if (!subscription || subscription.plan === SubscriptionPlans.free) {
    return (
      <div className="flex flex-col xl:flex-row lg:items-start justify-between gap-4">
        <div className="text-20 text-gray-500 whitespace-nowrap w-1/3">
          Subscription Type
        </div>
        <div className="w-full flex flex-col justify-between">
          <div className="flex items-center">
            <div>
              <div className="uppercase text-12 font-bold tracking-wide text-gray-500">
                Current Plan
              </div>
              <div className="mt-2 text-22 capitalize">
                {SubscriptionPlans.free}
              </div>
            </div>
          </div>
          <StartCheckoutFlowButton
            origin="account-settings"
            className="!rounded-full !px-12 mt-6 lg:ml-auto"
          />
        </div>
      </div>
    );
  }

  const locale = getLocale();

  const isCancelling = !!subscription.canceledAt;
  const isPaused = subscription.status === SubscriptionStatus.paused;

  let nextPaymentDate = subscription.periodEnd
    ? new Date(subscription.periodEnd).toLocaleDateString(locale, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : null;

  if (!subscription.periodEnd && isPaused) {
    nextPaymentDate = subscription.periodStart
      ? new Date(subscription.periodStart).toLocaleDateString(locale, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : null;
  }

  const nextTransactionTotals =
    paddleSubscription?.next_transaction?.details.totals;
  const currencyCode = nextTransactionTotals?.currency_code ?? "USD";

  const grandTotal = !!nextTransactionTotals
    ? formatPrice(
        parseInt(nextTransactionTotals.grand_total, 10) / 100,
        currencyCode
      )
    : null;

  let paymentTitleText = "Upcoming Payment";

  if (isCancelling) {
    paymentTitleText = "Cancelling On";
  } else if (isPaused) {
    paymentTitleText = "Paused Until";
  }

  return (
    <>
      <div className="flex items-center justify-between gap-4">
        <div className="xl:text-20 text-16 text-gray-500 whitespace-nowrap xl:w-1/3">
          Subscription Type
        </div>
        <div className="w-full xl:flex hidden items-center justify-between">
          <div className="flex items-center gap-9">
            <div>
              <div className="uppercase text-10 font-bold tracking-wide text-gray-500">
                Current Plan
              </div>
              <div className="mt-2 text-18 capitalize">{subscription.plan}</div>
            </div>

            <div>
              <div className="uppercase text-10 font-bold tracking-wide text-gray-500">
                Billing Cycle
              </div>
              <div className="mt-2 text-18 capitalize">
                {subscription.interval}
              </div>
            </div>
          </div>

          <StartCheckoutFlowButton
            origin="account-settings"
            className="!rounded-full !px-12 !min-h-fit !py-2"
          >
            Change Subscription
          </StartCheckoutFlowButton>
        </div>

        <div className="xl:hidden text-16 text-right capitalize font-light">
          {subscription.plan} Plan ({subscription.interval})
        </div>
      </div>

      <div className="flex items-center justify-between gap-4">
        <div className="xl:text-20 text-16 text-gray-500 whitespace-nowrap xl:w-1/3">
          {paymentTitleText}
        </div>
        <div className="w-full xl:flex hidden items-center justify-between">
          <div className="flex items-center gap-7 uppercase">
            <div>
              <div className="uppercase text-10 font-bold tracking-wide text-gray-500">
                Date
              </div>
              <div className="mt-2 text-18 capitalize">{nextPaymentDate}</div>
            </div>
            {!!grandTotal && !isCancelling && !isPaused && (
              <div>
                <div className="uppercase text-10 font-bold tracking-wide text-gray-500">
                  Amount
                </div>
                <div className="mt-2 text-18 capitalize">{grandTotal}</div>
              </div>
            )}
            {subscription.provider === "paddle" && (
              <Button
                onClick={() => setActivePanel(SettingsPanel.payments)}
                className="!rounded-full !text-14 !min-h-fit !py-2"
                variant="outlined"
              >
                View Payment History
              </Button>
            )}
          </div>
          {subscription.provider === "paddle" && (
            <Button
              onClick={startUpdatePayment}
              className="!rounded-full !text-14 !min-h-fit !py-2"
              variant="text"
            >
              Update Payment Method
            </Button>
          )}
        </div>
        <div className="xl:hidden text-right">
          <div className=" text-16">{nextPaymentDate}</div>
          {!!grandTotal && !isCancelling && !isPaused && (
            <div className="mt-2 text-18 capitalize">{grandTotal}</div>
          )}
        </div>
      </div>

      <div className="hidden xl:block w-fit ml-auto">
        {subscription.provider === "paddle" ? (
          <CancelSubscriptionButton />
        ) : (
          <Button variant="text" onClick={() => createStripePortalSession()}>
            Manage Subscription
          </Button>
        )}
      </div>

      <div className="xl:hidden flex flex-col items-center gap-4 mx-auto w-full pb-4">
        <StartCheckoutFlowButton
          origin="account-settings"
          fullWidth
          className="!rounded-full !w-3/4 !px-2 !min-h-fit !py-2"
        >
          Change Subscription
        </StartCheckoutFlowButton>
        {subscription.provider === "paddle" && (
          <Button
            onClick={() => setActivePanel(SettingsPanel.payments)}
            className="!rounded-full !w-3/4 !min-h-fit !py-2"
            variant="outlined"
          >
            View Payment History
          </Button>
        )}
        {subscription.provider === "paddle" && (
          <Button
            onClick={startUpdatePayment}
            className="!rounded-full !text-14 !min-h-fit !py-2"
            variant="text"
          >
            Update Payment Method
          </Button>
        )}
        <Divider className="my-3" />
        {subscription.provider === "paddle" ? (
          <CancelSubscriptionButton className="!min-h-fit !py-1 relative before:content-[''] before:absolute before:bottom-0 before:bg-white before:w-full before:h-px" />
        ) : (
          <Button variant="text" onClick={() => createStripePortalSession()}>
            Manage Subscription
          </Button>
        )}
      </div>
    </>
  );
};

export default SubscriptionDetails;
