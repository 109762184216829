import Head from "next/head";
import { useRouter } from "next/router";

import DesktopNavigation from "@musicfy/components/Navigation/DesktopNavigation";
import MobileNavigation from "@musicfy/components/Navigation/MobileNavigation";

import { APPS, type IAppInfo, findActiveSection } from "./constants";

const Navigation: React.FC<{
  toggleNavPinned: () => void;
  isNavPinned: boolean;
}> = ({ toggleNavPinned, isNavPinned }) => {
  const router = useRouter();

  const currentAppRoute = router.pathname.split("/")[1];
  const activeApp = !currentAppRoute
    ? undefined
    : APPS.find(
        ({ route }) =>
          currentAppRoute === route.split("/")[1] && !route.includes("http")
      ) ?? (APPS[0] as IAppInfo);

  const activeSection = !activeApp
    ? undefined
    : findActiveSection(router.asPath, activeApp.features);

  const lastPathSegment = router.asPath.split("/").pop();
  const voiceTitle = lastPathSegment === "voice" ? "" : lastPathSegment;

  const secondaryTitle = router.pathname.includes("[...voice]")
    ? `${decodeURIComponent(voiceTitle ?? "")} Voice Generator`
    : activeSection?.displayName;
  const pageTitle = !!secondaryTitle
    ? `Musicfy AI ${!!secondaryTitle ? `- ${secondaryTitle}` : ""}`
    : "Musicfy | Create AI Covers with your Favorite Voices!";

  return (
    <nav>
      <Head>
        <title>{pageTitle}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta
          name="description"
          property="og:description"
          content={
            activeSection?.subtitle ||
            "Use our industry-leading AI voice song generator to create covers with AI in any voice. Choose from 100,000+ voices or make a clone of yours."
          }
        />
        <link
          rel="icon"
          href="https://framerusercontent.com/images/0FLYNLQf4KYAg0O3pG4MXWtZRU.png"
          sizes="any"
        />
      </Head>
      <div className="block xl:hidden">
        <MobileNavigation />
      </div>

      <DesktopNavigation
        toggleNavPinned={toggleNavPinned}
        isNavPinned={isNavPinned}
      />
    </nav>
  );
};

export default Navigation;
