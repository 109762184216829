import { SubscriptionStatus, type Subscription } from "@prisma/client";

import { type PaddleSubscription } from "@musicfy/types/paddle-server";

import {
  AdditionalVoicesId,
  PRODUCT_ID_TO_PLAN_MAP,
  ProductId,
  type TAdditionalVoicesId,
} from "../contants/Subscriptions";

const extractPaddleSubscriptionData = (
  data: PaddleSubscription
): Subscription | null => {
  const subscriptionCheckoutItem = data.items.find((item) =>
    Object.values(ProductId).includes(item.price.product_id)
  );
  const customData = data.custom_data;

  if (!subscriptionCheckoutItem || !customData) {
    return null;
  }

  const additionalVoicesCheckoutItem = data.items.find((item) =>
    Object.values(AdditionalVoicesId).includes(
      item.price.id as TAdditionalVoicesId
    )
  );

  const subscriptionPrice = subscriptionCheckoutItem.price;
  const additionalVoicesPrice = additionalVoicesCheckoutItem?.price;

  const interval = data.billing_cycle.interval;

  const plan =
    PRODUCT_ID_TO_PLAN_MAP[subscriptionCheckoutItem.price.product_id];

  const userId = customData.userId;
  const customerId = data.customer_id;
  const subscriptionId = data.id;

  let startsAt = data.current_billing_period?.starts_at;
  let endsAt = data.current_billing_period?.ends_at;
  let status = data.status;

  if (data.scheduled_change?.action === "pause") {
    startsAt = data.scheduled_change.effective_at;
    endsAt = data.scheduled_change.resume_at || undefined;
    status = SubscriptionStatus.paused;
  }

  let canceledAt = null;

  if (data.scheduled_change && data.scheduled_change?.action === "cancel") {
    canceledAt = new Date();
  }

  const additionalVoiceSlots = additionalVoicesCheckoutItem?.quantity ?? 0;

  const totalPrice =
    Number(subscriptionPrice.unit_price.amount) +
    Number(additionalVoicesPrice?.unit_price.amount ?? 0) *
      additionalVoiceSlots;

  const subscriptionData: Subscription = {
    plan: plan,
    interval: interval,
    status: status,
    price: totalPrice,
    currency: subscriptionPrice.unit_price.currency_code,
    periodEnd: endsAt ? new Date(endsAt) : null,
    periodStart: startsAt ? new Date(startsAt) : null,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
    canceledAt: canceledAt,
    customerId: customerId,
    subscriptionId: subscriptionId,
    userId: userId,
    additionalVoiceSlots: additionalVoiceSlots,
    freeVoiceSlots: 0,
    provider: "paddle",
  };

  return subscriptionData;
};

export { extractPaddleSubscriptionData };
