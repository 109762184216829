import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { SubscriptionPlans } from "@prisma/client";
import { useSession } from "next-auth/react";

import { INFERENCE_MAX_QUOTA } from "@musicfy/contants/Features";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";
import { api } from "@musicfy/utils";

interface IFreeUsageCounterContext {
  remainingGenerations: number;
  ttl: number;
  inferenceQuota: typeof INFERENCE_MAX_QUOTA.free;
  resetGenerations: () => void;
  decrementGenerations: () => void;
  setTtl: React.Dispatch<React.SetStateAction<number>>;
}

export const FreeUsageCountdownTimer: React.FC = () => {
  const { ttl, resetGenerations, setTtl } = useFreeUsageCounterContext();

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTtl((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    if (ttl < 0) {
      resetGenerations();
      clearInterval(countdownInterval);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [resetGenerations, setTtl, ttl]);

  const hours = Math.floor(ttl / 3600);
  const minutes = Math.floor((ttl % 3600) / 60);
  const seconds = Math.floor(ttl % 60);

  return (
    <div className="text-14">
      Refreshes in{" "}
      <span className="font-semibold">
        {hours > 0 && `${hours}h `}
        {minutes > 0 && `${minutes}m `}
        {minutes === 0 && hours > 0 && `0m `}
        {seconds > 0 && `${seconds.toString().padStart(2, "0")}s`}
      </span>
    </div>
  );
};

const FreeUsageCounterContext = createContext<IFreeUsageCounterContext | null>(
  null
);

export function useFreeUsageCounterContext() {
  const freeUsageCounterContext = useContext(FreeUsageCounterContext);

  if (!freeUsageCounterContext) {
    throw new Error(
      "useFreeUsageCounterContext must be used within a AudioGenerationsProvider"
    );
  }

  return freeUsageCounterContext;
}

const FreeUsageCounterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { status } = useSession();
  const { subscription, isLoading: isSubscriptionLoading } =
    useSubscriptionContext();

  const [generationsUsed, setGenerationsUsed] = useState<number>(0);
  const [remainingGenerations, setRemainingGenerations] = useState<number>(0);
  const [ttl, setTtl] = useState<number>(0);

  const inferenceQuota = useMemo(() => {
    return INFERENCE_MAX_QUOTA[subscription?.plan || SubscriptionPlans.free];
  }, [subscription?.plan]);

  const { refetch } = api.audioGenerations.getRemainingUsage.useQuery(
    undefined,
    {
      onSuccess: (data) => {
        const { amountUsed, ttl } = data;

        setGenerationsUsed(amountUsed);
        setTtl(ttl);
      },
      retry: false,
      enabled: status === "authenticated",
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!isSubscriptionLoading) {
      setRemainingGenerations(inferenceQuota.limit - generationsUsed);
    }
  }, [isSubscriptionLoading, generationsUsed, inferenceQuota]);

  const decrementGenerations = useCallback(() => {
    const hasLimitOnInference = inferenceQuota.limit !== Infinity;

    if (!hasLimitOnInference) {
      return;
    }

    let _remainingGenerations = remainingGenerations - 1;

    if (_remainingGenerations <= 0) {
      _remainingGenerations = 0;
      refetch();
    }
    setRemainingGenerations(_remainingGenerations);
  }, [inferenceQuota.limit, refetch, remainingGenerations]);

  const resetGenerations = useCallback(() => {
    setRemainingGenerations(inferenceQuota.limit);
    setTtl(0);
  }, [inferenceQuota.limit]);

  const freeUsageCounterContextValue = {
    remainingGenerations,
    inferenceQuota,
    ttl,
    resetGenerations,
    decrementGenerations,
    setTtl,
  };

  return (
    <FreeUsageCounterContext.Provider value={freeUsageCounterContextValue}>
      {children}
    </FreeUsageCounterContext.Provider>
  );
};

export default FreeUsageCounterProvider;
